import spriteImg from './sprite';

if (typeof Element.prototype.clearChildren === 'undefined') {
    Object.defineProperty(Element.prototype, 'clearChildren', {
        configurable: true,
        enumerable: false,
        value: function () {
            while (this.firstChild) this.removeChild(this.lastChild);
        }
    });
}

export function solitairInitial(initial, gameFinishCallback, turn) {
    // const gameEl = document.getElementById('js-solitaire');

    const dealPileEl = document.getElementById('js-deck-pile');
    dealPileEl.clearChildren();

    const dealEl = document.getElementById('js-deck-deal');
    dealEl.clearChildren();

    const finishContainerEl = document.getElementById('js-finish');
    finishContainerEl.clearChildren();

    const deskContainerEl = document.getElementById('js-board');
    deskContainerEl.clearChildren();

    const deckPileEl = document.getElementById('js-deck-pile');
    dealEl.clearChildren();

    // const cardWidth = 71;
    // const cardHeight = 96;
    const state = {
        // clubs (♣), diamonds (♦), hearts (♥) and spades (♠)
        types: ['c', 'd', 'h', 's'],
        // 0 (black), 1 (red)
        colors: {'c': 0, 'd': 1, 'h': 1, 's': 0},
        cards: [
            // { el: null, type: 's', number: 0, facingUp: false }, {...}
        ],
        // dealer deck
        deal: {
            pile: {
                el: null,
                cards: [/* 0, 1, ... */]
            },
            deal: {
                el: null,
                cards: [/* 0, 1, ... */]
            },
        },
        // finish deck
        finish: [
            // { el: null, cards: [ 0, 1, ... ] }, {...}
        ],
        // playing desk
        desk: [
            // { el: null, cards: [ 0, 1, ... ]}, {...}
        ],
        // move target
        target: null,
        // moving
        moving: {
            card: {},
            element: null,
            index: -1,
            capture: false,
            container: {
                cards: [/* 0, 1, ... */]
            },
            target: null,
            origin: {},
            offset: {
                x: 0,
                y: 0,
            },
            destinations: []
        },
        startWith: null,
        turn: turn,
        moves_num: 0,
        score: 0,
        pileCycle: 0,
        options: {
            enable_hints: false,
            autocomplete: true,
            one_click: true,
        }
    };

    let hintDest = {index: -1, destination: null};
    const getCard = index => state.cards[index];

    const faceUp = card => {
        state.cards[card].facingUp = true;
        requestAnimationFrame(() => {
            state.cards[card].el.classList.add('card--front');
            state.cards[card].el.classList.remove('card--back');
        });
    };

    const faceDown = card => {
        state.cards[card].facingUp = false;
        state.cards[card].el.classList.remove('card--front');
        state.cards[card].el.classList.add('card--back');
    };

    const faceUpLastOnDesk = index => {
        const i = getLastOnDesk(index);
        if (i !== null) {
            const card = getCard(i);
            if (!card.facingUp && card) {
                console.log('faceUpLastOnDesk +5', card);
                state.score += 5;
            }
            ;
            faceUp(i);
        }
    };

    const appendToCard = (target, card) => {
        state.cards[target].el.appendChild(
            state.cards[card].el
        )
    };

    const appendToDesk = (desk, card) => {
        state.desk[desk].el.appendChild(
            state.cards[card].el
        )
    };

    const getLastOnDesk = desk => {
        const l = state.desk[desk].cards.length;
        if (l > 0) {
            return state.desk[desk].cards[l - 1];
        }
        return null;
    };

    const getLastOnPile = (pile, index) => {
        const l = state[pile][index].cards.length;
        if (l > 0) {
            const card = state[pile][index].cards[l - 1];
            return state.cards[card];
        }
        return {};
    };

    const getCardLocation = card => {
        for (let i = 0; i < 7; i++) {
            const index = state.desk[i].cards.indexOf(card);
            if (index > -1) {
                return {
                    location: 'desk',
                    pile: i,
                    index: index
                }
            }
        }

        for (let i = 0; i < 4; i++) {
            const index = state.finish[i].cards.indexOf(card);
            if (index > -1) {
                return {
                    location: 'finish',
                    pile: i,
                    index: index
                }
            }
        }

        for (let i of ['deal', 'pile']) {
            const index = state.deal[i].cards.indexOf(card);
            if (index > -1) {
                return {
                    location: 'deal',
                    pile: i,
                    index: index
                }
            }
        }
        // debugger;
        // 'Card not found!';
        console.log('Card not found!', card)
    };

    const getSubCards = card => {
        const {location, pile, index} = getCardLocation(card);

        return state[location][pile].cards.filter(
            (elem, i, array) => array.indexOf(elem) > index
        );
    };

    const getPile = (pile, index) => {
        return state[pile][index];
    };

    const moveCardTo = (dest, i, card) => {
        const {
            location,
            pile,
            index
        } = getCardLocation(card);

        // console.log({dest, i, card})

        if (/desk,\d+/.test(`${dest},${i}`)) {
            state.score = Math.max(state.score + (location === 'finish' ? -15 : 5), 0)
        } else if (/finish,\d+/.test(`${dest},${i}`)) {
            console.log('+10')
            state.score += 10
        }

        const moving = state[location][pile].cards.filter(
            (elem, i, array) => array.indexOf(elem) >= index
        );

        // remove from source
        state[location][pile].cards = state[location][pile].cards.filter(
            (elem, i, array) => moving.indexOf(elem) === -1
        );

        // append to destination
        state[dest][i].cards = state[dest][i].cards.concat(moving);

        // console.log(state);
    };

    const canBePlacedOnCard = (child, parent) => {
        const {type, number} = getCard(child);
        const {
            type: parentType,
            number: parentNumber
        } = getCard(parent);
        return (
            (parentNumber - 1) === number &&
            state.colors[parentType] !== state.colors[type]
        );
    };

    const placeCardTo = (dest, index, card) => {
        function remove(array, element) {
            const index = array.indexOf(element);

            if (index !== -1) {
                array.splice(index, 1);
            }
        }

        state[dest][index].cards.push(card);
        remove(state.deal.pile.cards, card);
    };

    function dealCards() {
        let card = 0;
        for (let i = 0; i < 7; i++) {
            for (let j = i; j < 7; j++) {
                const last = getLastOnDesk(j);
                if (last !== null) {
                    appendToCard(last, card)
                } else {
                    appendToDesk(j, card);
                }

                placeCardTo('desk', j, card);

                if (j === i) {
                    faceUp(card);
                }
                card++;
            }
        }
    }

    function resetGame() {
        state.moves_num = 0
        state.score = 0
        dealEl.classList.toggle('turn_one', state.turn === 1);

        // clear decks
        for (let i = 0; i < 7; i++) {
            state.desk[i].cards = [];
        }
        for (let i = 0; i < 4; i++) {
            state.finish[i].cards = [];
        }
        state.deal.pile.cards = [];
        state.deal.deal.cards = [];

        // randomise cards
        state.cards.sort(() => (Math.random() < .5) ? -1 : 1);

        const p = new Promise((resolve) => {
            // re-assign indexes
            requestAnimationFrame(() => {
                for (let i = 0, l = state.cards.length; i < l; i++) {
                    const {facingUp, el} = state.cards[i];
                    state.deal.pile.cards.push(i);

                    el.onmousedown = captureMove(i);
                    el.ontouchstart = captureMove(i);
                    el.onmouseup = releaseMove(i);
                    el.ontouchend = releaseMove(i);
                    el.onclick = handleClick(i);

                    if (facingUp) {
                        faceDown(i);
                    }
                    dealPileEl.appendChild(el);
                }
                dealCards();
                state.startWith = JSON.parse(JSON.stringify({
                    cards: state.cards.map((item) => ({...item, el: undefined})),
                    finish: state.finish.map((item) => ({...item, el: undefined})),
                    deal: state.deal,
                    desk: state.desk.map((item) => ({...item, el: undefined})),
                }))
                state.moves_num = 0
                state.score = 0
                resolve()
            })
        });
        return p
    }

    function updateDeal(index) {
        const {location, pile} = getCardLocation(index);

        if (location === 'deal' && pile === 'pile') {
            const max = state.deal.pile.cards.length - 1;
            const min = Math.max(-1, max - state.turn);

            for (let i = max; i > min; i--) {
                const card = state.deal.pile.cards[i];
                const {el} = getCard(card);
                faceUp(card);
                moveCardTo('deal', 'deal', card);
                dealEl.appendChild(el);
            }
        }
    };

    const handleClick = index => event => {
        // console.log('handleClick', event, state.moving.capture, index)
        event.stopPropagation();
        const {el, facingUp} = getCard(index);

        if (state.moving.capture) return;
        releaseMove()(event);

        if (facingUp && state.options.one_click) {
            const {location, pile} = getCardLocation(index);

            if (location === 'deal' && pile === 'deal') {
                const {
                    el: lastEl
                } = getLastOnPile('deal', 'deal');
                if (el !== lastEl) return;
            }

            const destinations = getAvailableDestinations(index, true);
            // console.log({location, pile, destinations})

            if (destinations.length > 0) {

                let {
                    target,
                    el: targetEl
                } = destinations[0];

                if (hintDest.index > -1) {
                    if (hintDest.destination.target.card == target.card) {
                        target = hintDest.destination.target;
                        targetEl = hintDest.destination.el;
                    }
                }

                const {
                    dest: destTarget,
                    pile: pileTarget,
                    card: cardTarget
                } = target;

                if ((location === 'finish') && (destTarget === 'finish')) {
                    return
                }

                moveCardTo(destTarget, pileTarget, cardTarget);

                // face up last cards on desk
                if (location === 'desk') {
                    faceUpLastOnDesk(pile);
                }
                targetEl.appendChild(el);
            } else {
                return;
            }
        } else {
            // is on deal deck
            updateDeal(index)
        }
        gameFinish();
    };

    function restartDeal() {
        state.deal.pile.cards = state.deal.deal.cards.reverse();
        state.deal.deal.cards = [];


        for (const card of state.deal.pile.cards) {
            const {el} = getCard(card);
            faceDown(card);
            deckPileEl.appendChild(el);
        }
        if (state.deal.pile.cards.length) {
            state.pileCycle += 1
            if (state.pileCycle >= 3 && (state.turn === 3)) {
                state.score = Math.max(state.score - 20, 0)
            }
            if (state.pileCycle >= 1 && (state.turn === 1)) {
                state.score = Math.max(state.score - 100, 0)
            }
        }
    }

    function getMousePosition(event) {
        if (['touchend', 'touchstart', 'touchmove'].indexOf(event.type) > -1) {
            if (event.changedTouches.length) {
                return {
                    x: event.changedTouches[event.changedTouches.length - 1].pageX,
                    y: event.changedTouches[event.changedTouches.length - 1].pageY
                }
            }
        } else {
            return {
                x: event.pageX,
                y: event.pageY
            };
        }
    }

    const handleMove = event => {
        // console.log(event)
        if (state.moving.capture) {
            const el = state.moving.element;
            const {x, y} = getMousePosition(event);
            el.style.left = `${x - state.moving.offset.x}px`;
            el.style.top = `${y - state.moving.offset.y}px`;
        }
    };

    const startMovingPosition = event => {
        const el = state.moving.element;
        const {x, y} = getMousePosition(event);
        const {top, left} = el.getBoundingClientRect();
        el.classList.add('card--moving');

        state.moving.offset = {
            x: x - left,
            y: y - top,
        };

        el.style.left = `${x - state.moving.offset.x}px`;
        el.style.top = `${y - state.moving.offset.y - 5}px`;
    };

    let moving;
    const captureMove = index => event => {
        event.preventDefault();
        event.stopPropagation();
        const {el, facingUp} = getCard(index);
        if (facingUp) {
            const {location, pile} = getCardLocation(index);
            if (location === 'deal' && pile === 'deal') {
                const {el: lastEl} = getLastOnPile('deal', 'deal');
                if (el !== lastEl) {
                    return false;
                }
            }
            moving = setTimeout(() => {
                state.moving.element = event.target;
                state.moving.capture = true;
                state.moving.index = index;
                state.moving.card = getCard(index);
                state.moving.origin = getCardLocation(index);

                startMovingPosition(event);

                const destinations = getAvailableDestinations(index);
                state.moving.destinations = destinations;

                if (state.options.enable_hints) {
                    for (const dest of destinations) {
                        dest.el.classList.add('finish-dest');
                    }
                }

                for (let i = 0, l = destinations.length; i < l; i++) {
                    const {top, left, width, height} = destinations[i].el.getBoundingClientRect();
                    state.moving.destinations[i].offset = {
                        top: top + window.scrollY,
                        left: left + window.scrollX,
                        width: width,
                        height: height,
                    }
                }

                // console.log(state.moving.destinations);
            }, 200);
        }
    };

    const dropCard = (x, y) => {
        console.log(['dropCard', x, y])
        for (const destination of state.moving.destinations) {
            const {width, height, left, top} = destination.offset;
            destination.el.classList.remove('finish-dest');
            // console.log(
            //     {x, y},
            //     {left, top},
            //     (x > left && x < left + width) &&
            //     (y > top && y < top + height)
            // )
            if (
                (x > left && x < left + width) &&
                (y > top && y < top + height)
            ) {
                const {dest, pile, card} = destination.target;
                moveCardTo(dest, pile, card);

                destination.el.appendChild(state.moving.element);

                // check game finish
                gameFinish();

                // face up last on desk
                const {
                    location: originLocation,
                    pile: originPile
                } = state.moving.origin;

                if (originLocation === 'desk') {
                    faceUpLastOnDesk(originPile);
                }
            }
        }
    };

    let release;
    // const releaseMove2 = (index) => {
    //     if (state.moving.capture) {
    //         return event => {
    //             clearTimeout(moving);
    //             clearTimeout(release);
    //             release = setTimeout(() => {
    //                 const {x, y} = getMousePosition(event);
    //                 requestAnimationFrame(() => {
    //                     dropCard(x, y);
    //
    //                     state.moving.element.classList.remove('card--moving');
    //                     state.moving.element.style.left = '';
    //                     state.moving.element.style.top = '';
    //                     state.moving.element = null;
    //                     state.moving.capture = false;
    //                     console.log('release');
    //                 });
    //             }, 100);
    //         }
    //     } else {
    //         // return handleClick(index)
    //     }
    // };
    const releaseMove = (index) => {
        return event => {
            clearTimeout(moving);
            clearTimeout(release);
            if (state.moving.capture) {
                release = setTimeout(() => {
                    const {x, y} = getMousePosition(event);
                    requestAnimationFrame(() => {
                        dropCard(x, y);

                        state.moving.element.classList.remove('card--moving');
                        state.moving.element.style.left = '';
                        state.moving.element.style.top = '';
                        state.moving.element = null;
                        state.moving.capture = false;
                        console.log('release');
                    });
                }, 100);
            } else {
                if ((index !== undefined) && (event.type == 'touchend'))
                    handleClick(index)(event)
            }
        }
    }

    const getAvailableDestinations = (index, first = false) => {
        const {type, number} = getCard(index);
        const destinations = [];
        if (number === 1) { // aces
            // finish pile
            for (let i = 0; i < 4; i++) {
                const {cards, el} = getPile('finish', i);
                if (cards.length === 0) {
                    destinations.push({
                        el: el,
                        target: {
                            dest: 'finish',
                            pile: i,
                            card: index
                        }
                    });

                    if (first) return destinations;
                }
            }
        }
        // other cards
        // move to finish pile
        const subCards = getSubCards(index);
        if (!subCards.length > 0) {
            for (let i = 0; i < 4; i++) {
                const l = state.finish[i].cards.length;
                if (l + 1 === number) {
                    const {
                        type: lastType
                    } = getLastOnPile('finish', i);

                    if (lastType === type) {
                        destinations.push({
                            el: state.finish[i].el,
                            target: {
                                dest: 'finish',
                                pile: i,
                                card: index
                            }
                        });
                        if (first) return destinations;
                        break;
                    }
                }
            }
        }
        // desk pile
        for (let i = 0; i < 7; i++) {
            const last = getLastOnDesk(i);
            if (last !== null) {
                if (canBePlacedOnCard(index, last)) {
                    destinations.push({
                        el: state.cards[last].el,
                        target: {
                            dest: 'desk',
                            pile: i,
                            card: index
                        }
                    });
                    if (first) return destinations;
                }
            } else { // empty desk, accepts only kings
                if (number === 13) { // kings
                    destinations.push({
                        el: state.desk[i].el,
                        target: {
                            dest: 'desk',
                            pile: i,
                            card: index
                        }
                    });
                    if (first) return destinations;
                }
            }
        }
        return destinations;
    };


    const gameFinish = () => {
        // console.error(13)
        clearHint([])
        state.moves_num += 1
        if (!state.cards.some((item) => (!item.facingUp)) && (state.options.autocomplete)) {

            // раскладывать по колоде
            const cl = []
            for (let _i = 0; _i < 200; _i++) {
                _i += 1
                cl.push(
                    setTimeout(() => {
                        if (state.finish.some((item) => item.cards.length < 13)) {
                            for (let i = 0; i < 7; i++) {
                                const card = getLastOnDesk(i)
                                let destinations = []
                                if (card != null) {
                                    destinations = getAvailableDestinations(card, true)
                                    destinations.filter((item) => (item.target.dest == 'finish')).forEach((destination) => {
                                        moveCardTo(destination.target.dest, destination.target.pile, destination.target.card)
                                        destination.el.appendChild(state.cards[card].el)
                                    })
                                }
                            }

                            // const finish = state.finish.some((item) => item.cards.length < 13)
                            // console.log({_i, finish})
                        } else {
                            cl.forEach(clearTimeout)
                            gameFinishCallback(true)
                            return;
                        }
                    }, 50 * _i)
                )
                // delay(100)
            }
        }

        // game finish check
        for (let i = 3; i >= 0; i--) {
            const l = state.finish[i].cards.length;
            if (l < 13) {
                gameFinishCallback(false)
                return;
            }
        }

        if (gameFinishCallback) {
            gameFinishCallback(true)
        }
    };

    function initSolitaire() {
        // add sprite
        const css = document.createElement('style');
        // const styles = `.card--front { background-image: url("${spriteImg}"); }`;
        // css.appendChild(document.createTextNode(styles));
        document.head.appendChild(css);

        dealPileEl.onclick = restartDeal;
        window.onmousemove = handleMove;
        window.ontouchmove = handleMove;
        // window.onmouseup = releaseMove;
        // window.ontouchend = releaseMove;

        // create aces decks
        for (let i = 0; i < 4; i++) {
            const el = document.createElement('div');
            el.classList.add(
                'aces',
                `aces--${i}`
            );
            state.finish.push({
                el: el,
                cards: []
            });
            finishContainerEl.appendChild(el);
        }

        // create desk decks
        for (let i = 0; i < 7; i++) {
            const el = document.createElement('div');
            el.classList.add(
                'seven',
                `seven--${i}`
            );
            state.desk.push({
                el: el,
                cards: []
            });
            deskContainerEl.appendChild(el);
        }

        if (initial) {
            state.startWith = initial.startWith;
            state.moves_num = initial.moves_num;
            state.score = initial.score;
            state.turn = initial.turn;
            state.options = initial.options;
            dealEl.classList.toggle('turn_one', state.turn === 1);

            initial.cards.map((item, i) => {
                    // console.log(i)
                    const el = document.createElement('div');
                    el.classList.add(
                        'card',
                        `card--${item.type}-${item.number}`,
                        'card--back'
                    );
                    state.cards.push({
                        el: el,
                        type: item.type,
                        number: item.number,
                        facingUp: item.facingUp
                    });

                    // el.onmousedown = captureMove(i);
                    // el.onmouseup = releaseMove;
                    // el.onclick = handleClick(i);

                    dealPileEl.appendChild(el);
                }
            )
            initial.finish.map((item, i) => {
                state.finish[i].cards = item.cards

                item.cards.map((card, j) => {
                    faceUp(card)
                    state.finish[i].el.appendChild(state.cards[card].el)
                    // if (j == 0) {
                    //     state.finish[i].el.appendChild(state.cards[card].el)
                    // } else {
                    //     state.finish[i].cards[j - 1].appendChild(state.cards[card].el)
                    // }
                })


                // item.cards.map(() => {
                // moveCardTo('finish', i, item)
                // })
                // const el = document.createElement('div');
                // el.classList.add(
                //     'aces',
                //     `aces--${i}` //???
                // );
                // state.finish[].push({
                //     el: el,
                //     cards: item.cards
                // });
                // finishContainerEl.appendChild(el);
            })

            const p = new Promise((resolve) => {
                requestAnimationFrame(() => {
                    // state.deal.deal = initial.deal.deal
                    // state.deal.pile = initial.deal.pile

                    for (let i = 0, l = state.cards.length; i < l; i++) {
                        const el = state.cards[i].el;

                        el.onmousedown = captureMove(i);
                        el.ontouchstart = captureMove(i);
                        el.onmouseup = releaseMove(i);
                        el.ontouchend = releaseMove(i);
                        el.onclick = handleClick(i);

                        // if (state.deal.pile.cards.indexOf(i) > -1) {
                        //     state.cards[i].facingUp = false
                        //     dealPileEl.appendChild(el)
                        // }
                        // if (state.deal.deal.cards.indexOf(i) > -1) {
                        //     faceUp(i)
                        //     dealEl.appendChild(el)
                        // }
                    }

                    initial.deal.pile.cards.map((item) => {
                        faceDown(item)
                        dealPileEl.appendChild(state.cards[item].el)
                        state.deal.pile.cards.push(item)
                    })
                    initial.deal.deal.cards.map((item) => {
                        faceUp(item)
                        dealEl.appendChild(state.cards[item].el)
                        state.deal.deal.cards.push(item)
                    })


                    initial.desk.map((item, i) => {
                        const cards = item.cards
                        cards.map((item, j) => {
                            if (j == 0) {
                                appendToDesk(i, item);
                            } else {
                                appendToCard(cards[j - 1], item);
                            }
                            placeCardTo('desk', i, item);

                            if (state.cards[item].facingUp)
                                faceUp(item);
                        })
                    })
                    // dealCards();
                    resolve()
                })
            })

            return p
        } else {
            // create all cards
            for (let i = 0; i < 4; i++) {
                for (let j = 1; j <= 13; j++) {
                    const el = document.createElement('div');
                    el.classList.add(
                        'card',
                        `card--${state.types[i]}-${j}`,
                        'card--back'
                    );

                    state.cards.push({
                        el: el,
                        type: state.types[i],
                        number: j,
                        facingUp: false
                    });
                }
            }

            return resetGame();
        }
    }

    function clearHint(destinations) {
        //возвращает true при завершении круга
        state.cards.forEach((item) => {
            item.el.classList.remove('hint')
        })

        state.finish.forEach((item) => {
            item.el.classList.remove('hint')
        })

        if (hintDest.index >= (destinations.length - 1)) {
            hintDest = {index: -1, destination: null}
            return true
        }
        return false
    };

    function showHint() {
        let destinations = []

        for (let i = 0; i < 7; i++) {
            const last = getLastOnDesk(i);
            if (last !== null) {
                if (state.cards[last].facingUp) {
                    destinations = destinations.concat(getAvailableDestinations(last))
                }
            }
        }

        const lastDealCard = getLastOnPile('deal', 'deal');

        for (let i = 0, l = state.cards.length; i < l; i++) {
            if (state.cards[i] == lastDealCard) {
                if (lastDealCard.facingUp) {
                    destinations = destinations.concat(getAvailableDestinations(i))
                    break
                }
            }
        }

        const clearCycle = clearHint(destinations);

        if (clearCycle) {
            return
        }

        for (let i = 0, l = destinations.length; i < l; i++) {
            const destination = destinations[i]
            if (i > hintDest.index) {
                hintDest.index = i
                hintDest.destination = destination

                state.cards[destination.target.card].el.classList.toggle('hint', true)
                destination.el.classList.toggle('hint', true)
                state.score = Math.max(state.score - 15, 0)
                break
            }
        }
    }

    return {
        resetGame: resetGame,
        initSolitaire: initSolitaire,
        showHint: showHint,
        state: state,
    }
// window.onload = initSolitaire;
}
